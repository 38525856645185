import * as React from 'react';
import Chip from '@material-ui/core/Chip';
import { useTranslate, useRecordContext } from 'react-admin';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { Record } from 'ra-core';
interface Props {
    record?: Record;
    basePath?: string;
    label?: string;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        in_search: {
            color: 'black',
            backgroundColor: 'Gold',
        },
        driver_notified: {
            color: 'black',
            backgroundColor: 'Gold',
        },
        conceled: {
            color: 'white',
            backgroundColor: 'gray',
        },
        engaged: {
            color: 'white',
            backgroundColor: 'blue',
        },
        conceled_by_driver: {
            color: 'white',
            backgroundColor: 'gray',
        },
        accepted: {
            color: 'white',
            backgroundColor: 'orange',
        },
        in_place: {
            color: 'white',
            backgroundColor: 'orange',
        },
        started: {
            color: 'white',
            backgroundColor: 'green',
        },
        loaded: {
            color: 'white',
            backgroundColor: 'green',
        },
        shipped: {
            color: 'white',
            backgroundColor: 'green',
        },
        shipping_confirmed: {
            color: 'white',
            backgroundColor: 'green',
        },
        shipping_not_confirmed: {
            color: 'white',
            backgroundColor: 'tomato',
        },
        payed: {
            color: 'white',
            backgroundColor: 'RoyalBlue',
        },
        not_payed: {
            color: 'white',
            backgroundColor: 'tomato',
        },
    })
);
const CourseStatusField = props => {
    const translate = useTranslate();
    const record = useRecordContext();
    const classes = useStyles({});
    if (record) {
        const statut = props.source ? record[props.source] : record.statut;
        return statut ? (
            <Chip
                size="small"
                label={translate(`resources.courses.course_statut.${statut}`)}
                className={classes[statut]}
            />
        ) : null;
    } else return null;
};
export default CourseStatusField;
