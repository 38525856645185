import * as React from 'react';
import { FC, useCallback } from 'react';
import {
    Show,
    ReferenceField,
    TabbedShowLayout,
    Tab,
    TextField,
    useRecordContext,
} from 'react-admin';

import { RaBox, BoxedShowLayout } from 'ra-compact-ui';

import { Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Course, FieldProps } from '../types';

import CourseStatusField from './fields/CourseStatutField';
import CodeField from './fields/CodeField';
import FullNameField from '../fields/users/FullNameField';
import DateTimeField from '../fields/common/DateTimefield';
import PriceField from '../fields/common/PriceField';

import PayCourseBtn from './btns/PayCourseBtn';

const Title: FC<FieldProps<Course>> = ({ record }) =>
    record ? <Typography>Réservation</Typography> : null;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        detailsBox: {
            paddingLeft: '50px',
            marginLeft: '50px',
        },
        element: {
            // marginRight: '30px',
        },
        section: {
            boxShadow: '-5px 0px 5px #ccc',
            padding: '20px',
            marginTop: '20px',
        },
        photosSection: {
            boxShadow: '-5px 5px 15px #ccc',
            padding: '20px',
            marginTop: '20px',
            margin: '20px',
            flexBasis: '100%',
        },
        headerSection: {
            fontWeight: 700,
            backgroundColor: '#eee',
            padding: '20px',
        },
        sectionCol: {
            flexGrow: 1,
            width: '33%',
            marginBottom: '10px',
        },
        boxTitle: {
            width: '100%',
            marginBottom: '10px',
            flexShrink: 0,
        },
    })
);

const can_pay = statut => ['finished', 'not_payed'].includes(statut);

const PayCourseForm = () => {
    const record = useRecordContext();
    const { id, statut } = record;
    const handleChange = useCallback(() => true, []);

    return can_pay(statut) ? (
        <>
            <PayCourseBtn onChange={handleChange} courseId={id} />
        </>
    ) : null;
};
/**
 * Start Inspection Tab View
 */
const CourseDetailsSection = (props: any) => {
    const classes = useStyles();
    // const { record } = props;
    // const driverComponent =
    //     record.driver !== null ? (
    //         <ReferenceField
    //             label="resources.courses.fields.driver"
    //             source="driver"
    //             reference="livreurs"
    //             className={classes.sectionCol}
    //         >
    //             <FullNameField />
    //         </ReferenceField>
    //     ) : (
    //         <></>
    //     );
    return (
        <BoxedShowLayout>
            <RaBox display="flex">
                <RaBox
                    display="inline-flex"
                    flexDirection="column"
                    flex="25%"
                    className={classes.element}
                >
                    <CodeField
                        addLabel={true}
                        label="resources.courses.fields.code"
                        className={classes.element}
                        source="code"
                    />
                    <DateTimeField
                        source="created_at"
                        addLabel={true}
                        label="resources.courses.fields.created_at"
                        className={classes.element}
                    />
                    <RaBox className={classes.element}>
                        <CourseStatusField
                            addLabel={true}
                            label="resources.courses.fields.statut"
                            className={classes.element}
                        />
                        <PayCourseForm />
                    </RaBox>
                    <ReferenceField
                        label="resources.courses.fields.user"
                        source="user"
                        reference="users"
                        className={classes.element}
                    >
                        <FullNameField />
                    </ReferenceField>
                </RaBox>

                <RaBox
                    display="flex"
                    flexWrap="wrap"
                    className={classes.detailsBox}
                >
                    <RaBox
                        flex="0 0 100%"
                        display="flex"
                        justifyContent="space-around"
                        flexGrow={4}
                        className={classes.headerSection}
                    >
                        <TextField
                            addLabel={true}
                            label="resources.courses.fields.google_distance"
                            className={classes.sectionCol}
                            source="google_distance"
                        />
                        <TextField
                            addLabel={true}
                            label="resources.courses.fields.google_duration"
                            className={classes.sectionCol}
                            source="google_duration"
                        />
                        <PriceField
                            addLabel={true}
                            source="payed_price"
                            label="resources.courses.fields.payed_price"
                        />
                    </RaBox>
                    <RaBox
                        flex="0 0 100%"
                        display="flex"
                        justifyContent="space-around"
                        flexWrap="wrap"
                        className={classes.section}
                    >
                        <RaBox flex="0 0 100%">
                            <Typography variant="h6">Client</Typography>
                        </RaBox>

                        <TextField
                            addLabel={true}
                            label="resources.courses.fields.the_client"
                            className={classes.sectionCol}
                            source="user.name"
                        />
                        <TextField
                            addLabel={true}
                            label="resources.courses.fields.client_phone"
                            className={classes.sectionCol}
                            source="user.phone"
                        />

                        <TextField
                            addLabel={true}
                            label="resources.courses.fields.from_city"
                            className={classes.sectionCol}
                            source="from_city"
                        />
                        <RaBox display="flex" flexWrap="wrap" flexGrow="1">
                            <TextField
                                addLabel={true}
                                label="resources.courses.fields.from_address1"
                                className={classes.sectionCol}
                                source="from_address1"
                            />
                            <TextField
                                addLabel={true}
                                label="resources.courses.fields.from_address2"
                                className={classes.sectionCol}
                                source="from_address2"
                            />

                            <TextField
                                addLabel={true}
                                label="resources.courses.fields.from_country"
                                className={classes.sectionCol}
                                source="from_country"
                            />
                            <TextField
                                addLabel={true}
                                label="resources.courses.fields.from_name"
                                className={classes.sectionCol}
                                source="from_name"
                            />
                            <TextField
                                addLabel={true}
                                label="resources.courses.fields.from_zipcode"
                                className={classes.sectionCol}
                                source="from_zipcode"
                            />
                        </RaBox>
                    </RaBox>
                    <RaBox
                        flex="0 0 100%"
                        display="flex"
                        justifyContent="space-around"
                        flexWrap="wrap"
                        className={classes.section}
                    >
                        <RaBox flex="0 0 100%">
                            <Typography variant="h6">Livraison</Typography>
                        </RaBox>
                        <TextField
                            addLabel={true}
                            label="resources.courses.fields.name_driver"
                            source="livreur.name"
                            className={classes.sectionCol}
                        />
                        <TextField
                            addLabel={true}
                            label="resources.courses.fields.phone_driver"
                            source="livreur.phone"
                            className={classes.sectionCol}
                        />
                        <RaBox display="flex" flexWrap="wrap" flexGrow="1">
                            {/* {driverComponent} */}
                            <TextField
                                addLabel={true}
                                label="resources.courses.fields.to_address1"
                                className={classes.sectionCol}
                                source="to_address1"
                            />
                            <TextField
                                addLabel={true}
                                label="resources.courses.fields.to_address2"
                                className={classes.sectionCol}
                                source="to_address2"
                            />
                            <TextField
                                addLabel={true}
                                label="resources.courses.fields.to_city"
                                className={classes.sectionCol}
                                source="to_city"
                            />
                            <TextField
                                addLabel={true}
                                label="resources.courses.fields.to_country"
                                className={classes.sectionCol}
                                source="to_country"
                            />
                            <TextField
                                addLabel={true}
                                label="resources.courses.fields.to_zipcode"
                                className={classes.sectionCol}
                                source="to_zipcode"
                            />
                        </RaBox>
                    </RaBox>
                </RaBox>
            </RaBox>
        </BoxedShowLayout>
    );
};
/**
 * Show Component
 */
const CourseShow = (props: any) => {
    return (
        <Show {...props} component="div" tltle={<Title />}>
            <TabbedShowLayout>
                <Tab label="Detail ">
                    <CourseDetailsSection {...props} />
                </Tab>
                {/* <Tab label="Historique">
                    <CourseEventList />
                </Tab> */}
            </TabbedShowLayout>
        </Show>
    );
};

export default CourseShow;
