import * as React from 'react';
import { SelectInput, useTranslate } from 'react-admin';

const toChoices = (items, translate) =>
    items.map(item => ({
        id: item,
        name: translate(`resources.users.civilities.${item}`),
    }));
const CivilityInput = props => {
    const translate = useTranslate();
    const values = ['monsieur', 'madame'];
    return (
        <SelectInput
            choices={toChoices(values, translate)}
            {...props}
            emptyText="Tout"
        />
    );
};

export default CivilityInput;
