import { AuthProvider } from 'ra-core';

const authProvider: AuthProvider = {
    login: params => {
        const { username, password } = params;
        const request = new Request(
            process.env.REACT_APP_REST_API_URL + '/auth/admins/signin',
            {
                method: 'POST',
                body: JSON.stringify({
                    email: username,
                    username: username,
                    password,
                    role: 'admin',
                }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            }
        );
        return fetch(request, { credentials: 'include' })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data && data.roles) {
                    localStorage.setItem('user', data);
                    if (data.is_o) {
                        localStorage.setItem('role', 'operator');
                    } else if (data.is_a) {
                        localStorage.setItem('role', 'admin');
                    }
                    //redirect to alerts page after each login
                    return Promise.resolve();
                } else {
                    throw new Error(data.message);
                }
            })
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
    },
    logout: params => {
        //Clear localstorage
        localStorage.removeItem('user');
        localStorage.removeItem('role');
        //Logout from server
        const logout_request = new Request(
            process.env.REACT_APP_REST_API_URL + '/auth/admins/signout',
            {
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json' }),
            }
        );

        return fetch(logout_request, { credentials: 'include' })
            .then(response => {
                return Promise.resolve();
            })
            .catch(err => {
                console.error(err);
                return Promise.resolve();
            });
    },
    checkError: params => {
        const status = params.status;
        // if (status === 500 || status === 401) {
        //   localStorage.removeItem("user");
        //   localStorage.removeItem("role");
        //   return Promise.reject();
        // }
        if (status === 401) {
            localStorage.removeItem('user');
            localStorage.removeItem('role');
            return Promise.reject();
        }
        // return Promise.resolve(new Error(params.message));
        return Promise.resolve();
    },
    checkAuth: params => {
        return localStorage.getItem('user') && localStorage.getItem('role')
            ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions: params => {
        const role = localStorage.getItem('role');
        return role ? Promise.resolve(role) : Promise.reject();
    },
};

export default authProvider;
