import { TranslationMessages } from 'ra-core';
import frenchMessages from 'ra-language-french';

const customFrenchMessages: TranslationMessages = {
    ...frenchMessages,
    global: {
        auth: {
            email: 'Email',
            sign_in: 'Entrer',
            delete_account: 'Envoyer',
            account_deletion: 'Supprimer le compte',
            password: 'Mot de Passe',
            sign_in_error: "Erreur d'Authentification Failed",
        },
    },
    pos: {
        search: 'Rechercher',
        configuration: 'Configuration',
        language: 'Langue',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur',
        },
        dashboard: {
            subscriptions_today: {
                title: 'Nouveaux Clients',
                subtitle: "Aujourd'hui",
            },
            subscriptions_total: {
                title: 'Total Clients',
                subtitle: '',
            },
            courses_today: {
                title: 'Nouvelles Courses',
                subtitle: "Aujourd'hui",
            },
            courses_total: {
                title: 'Total courses',
                subtitle: '',
            },
            drivers_online: {
                title: 'Chaufeurs Connectés',
                subtitle: 'Actuellement',
            },
            welcome: {
                title: 'Bienvenue dans Boxroad',
                subtitle: 'Ceci est le back-office Boxroad..',
                aor_button: 'Site web de Boxroad',
                demo_button: 'Code source de cette démo',
            },
        },
        menu: {
            sales: 'Ventes',
            prducts: 'Produits',
            users: 'Utilisateurs',
            delivery: 'Livraisons',
        },
        actions: {
            concel: 'Annuler',
        },
    },
    resources: {
        users: {
            name: 'Client |||| Clients',
            fields: {
                name: 'Nom Prénom',
                email: 'Email',
                phone: 'Tél',
                civility: 'Genre',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                birthdate: 'date de Naissance',
                socialReason: 'Raison Sociale',
                created_at: "Date d'inscription",
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
                nb_courses: 'N de Commandes',
            },
            civilities: {
                monsieur: 'M',
                madame: 'Mme',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                quizzes: 'Quizzes',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        drivers: {
            name: 'Chauffeur |||| Chauffeurs',
            fields: {
                name: 'Nom Prénom',
                email: 'Email',
                phone: 'Tél',
                civility: 'Genre',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                birthdate: 'date de Naissance',
                socialReason: 'Raison Sociale',
                created_at: "Date d'inscription",
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
                nb_courses: 'N de Commandes',
            },
            civilities: {
                monsieur: 'M',
                madame: 'Mme',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                quizzes: 'Quizzes',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le Chauffeur',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        admins: {
            name: 'Administrateur |||| Administrateurs',
            fields: {
                name: {
                    first: 'Prénom',
                    last: 'Nom',
                },
                email: 'Email',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                socialReason: 'Raison Sociale',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                commands: 'Commandes',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        operators: {
            name: 'Opérateur |||| Opérateurs',
            fields: {
                name: {
                    first: 'Nom',
                    last: 'Prénom',
                    full: 'Nom Complet',
                },
                email: 'Email',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                socialReason: 'Raison Sociale',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                commands: 'Commandes',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        banners: {
            name: 'Bannière |||| Bannières',
            fields: {
                name: 'Nom',
                image: 'Image',
                target: 'Cible',
                targetModel: 'Type',
                category: 'Catégorie',
                feature: 'Feature',
            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
        },
        courses: {
            name: 'Course |||| Courses',
            fields: {
                user: 'Client',
                code: 'Numéro',
                code_str: 'Numéro',
                the_client: 'Le nom de client',
                client_phone: 'Le numéro de phone de client',
                from: 'De',
                from_address1: "De l'adresse",
                from_address2: "De l'adresse 2",
                from_city: 'De la ville',
                from_country: 'De la conmpagne',
                from_name: 'Nom de l’entreprise ou du particulier',
                from_zipcode: 'Code postal',
                to: 'Vers',
                to_address: "Vers l'adresse",
                to_address1: "Vers l'adresse",
                to_address2: "Vers l'adresse 2",
                to_city: 'Vers la ville',
                to_country: 'Vers la conmpagne',
                to_name: 'Nom de l’entreprise ou du particulier',
                to_zipcode: 'Code postal',
                name_driver: 'Nom et Prénom de chauffeur',
                phone_driver: 'Téléphone de chauffeur',
                notified_drivers: 'Chauffeurs notifiés',
                google_distance: 'Distance Estimée Google',
                google_duration: 'Durée Estimée Google',
                statut: 'Statut',
                client_note: 'Note client',
                client_comment: 'Commentaire clent',
                payed_price: 'Prix Payé',
                estimated_price: 'Prix Estimé',
                created_at: 'Date de Création',
                t: '-',
            },
            course_statut: {
                new: 'Nouvelle',
                in_search: 'En Recherche',
                driver_notified: 'En attente de Chauffeur',
                conceled_by_client: 'Abandonnée par Client',
                conceled_by_driver: 'Abandonnée par Chauffeur',
                accepted: 'Acceptée',
                in_place: 'Chauffeur sur place',
                started: 'Démarrée',
                finished: 'Terminée (Non encore payée)',
                payed: 'Payée',
                not_payed: 'Paiement échoué',
                conceled:'Abandonnée par Client',
                engaged: 'Engagée',
                loaded:'Chargée',
                shipped:'Livrée',
                shipping_confirmed:'Terminée :le client confirme la livraison',
                shipping_not_confirmed:'Terminée : le client ne confirme pas la livraison',
            },
            actions: {
                pay: 'Payer',
            },
        },
        course_events: {
            name: 'Evénement |||| Evénements',
            fields: {
                source: 'Déclanché par',
                to_statut: 'Statut',
                created_at: 'Date de Création',
            },
            source_types: {
                user: 'Client',
                driver: 'Chauffeur',
                operator: 'Opérateur',
                admin: 'Administrateur',
            },
        },
    },
};

export default customFrenchMessages;
