import * as React from 'react';
import { FC } from 'react';
import {
    Create,
    TextInput,
    ReferenceInput,
    SelectInput,
    TextField,
    Toolbar,
    FormWithRedirect,
    required,
    minLength,
    maxLength,
} from 'react-admin';

import MyImageInput from '../inputs/MyImageInput';

import { Box, Card, CardContent } from '@material-ui/core';
import { Course, FieldProps } from '../types';

const CourseCreate = (props: any) => {
    return (
        <Create title={<CourseTitle />} component="div" {...props}>
            <CourseForm />
        </Create>
    );
};

const CourseTitle: FC<FieldProps<Course>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;

const CourseForm = (props: any) => {
    const validateName = [required(), minLength(1), maxLength(30)];

    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box flex={2} display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <TextInput
                                        type="title"
                                        source="title"
                                        resource="categories"
                                        label="resources.categories.fields.title"
                                        validate={validateName}
                                        fullWidth
                                    />
                                    <ReferenceInput
                                        label="resources.categories.fields.parent"
                                        source="parent"
                                        reference="categories"
                                        allowEmpty
                                    >
                                        <SelectInput
                                            optionText="title"
                                            allowEmpty
                                        />
                                    </ReferenceInput>
                                    <TextInput
                                        type="description"
                                        source="description"
                                        resource="categories"
                                        label="resources.categories.fields.description"
                                        validate={validateName}
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <MyImageInput
                                        source="image"
                                        label="resources.categories.fields.image"
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="categories"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default CourseCreate;
