import React, { useState } from 'react';
import {
    makeStyles,
    Card,
    CardContent,
    TextField,
    Button,
    Typography,
    Avatar,
    Snackbar,
    SnackbarContent,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
    },
    card: {
        maxWidth: 300,
        width: '100%',
        borderRadius: 16,
        padding: theme.spacing(3),
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    },
    avatar: {
        margin: '0 auto',
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#c5a47e',
        color: 'white',
        '&:hover': {
            backgroundColor: '#b08d6a',
        },
    },
    title: {
        marginBottom: theme.spacing(2),
        color: '#333',
        textAlign: 'center',
    },
    textField: {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#e0e0e0',
            borderRadius: '16px',
          },
          '&:hover fieldset': {
            borderColor: '#c5a47e',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#c5a47e',
          },
        },
        '& .MuiInputLabel-root': {
          color: '#c5a47e',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#c5a47e',
        },
    },
    successAlert: {
        backgroundColor: '#4caf50',
    },
    icon: {
        fontSize: 20,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(1),
    },
    errorAlert: {
        backgroundColor: theme.palette.error.main,
    },
}));

const DeleteAccount = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [open, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    const validateEmail = email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    
    const handleSubmit = e => {
        e.preventDefault();
        if (!email) {
            setEmailError('L\'email est requis');
            return;
        }
        if (!validateEmail(email)) {
            setEmailError('Adresse email invalide');
            return;
        }
        setEmailError('');
        // Add your delete account logic here
        setIsSuccess(true);
        setSnackbarMessage('Votre demande sera traitée. Merci');
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardContent>
                    <Avatar className={classes.avatar}>
                        <DeleteIcon />
                    </Avatar>
                    <Typography
                        component="h1"
                        variant="h5"
                        className={classes.title}
                    >
                        Supprimer le compte
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={e => {
                                setEmail(e.target.value);
                                setEmailError('');
                            }}
                            className={classes.textField}
                            error={!!emailError}
                        />
                        {emailError && (
                            <Typography variant="caption" className={classes.errorText}>
                                {emailError}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                        >
                            Envoyer
                        </Button>
                    </form>
                </CardContent>
            </Card>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <SnackbarContent 
                    className={isSuccess ? classes.successAlert : classes.errorAlert}
                    message={
                        <span className={classes.message}>
                            {isSuccess ? <CheckCircleIcon className={classes.icon} /> : <ErrorIcon className={classes.icon} />}
                            {snackbarMessage}
                        </span>
                    }
                />
            </Snackbar>
        </div>
    );
};

export default DeleteAccount;