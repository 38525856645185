import React, { useState, useEffect, useCallback, FC } from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import { Card, CardContent } from '@material-ui/core';

import NbSubscriptions from './NbSubscriptions';
import NbDrivers from './NbDrivers';
import NbCourses from './NbCourses';

interface State {
    nbNewClients?: number;
    nbClients?: number;
    nbNewCourses?: number;
    nbCourses?: number;
    nbConnectedDrivers?: number;
}

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
    sectiontitle: { fontSize: 14 },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard: FC = () => {
    const [state, setState] = useState<State>({});
    const version = useVersion();
    const dataProvider = useDataProvider();

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const fetchNewClientsCount = useCallback(async () => {
        let query = {
            filter: { created_at: { $gte: today.toDateString() } },
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 1 },
            select: 'name',
        };
        const { total: nbNewClients } = await dataProvider.getList(
            'users',
            query
        );

        setState(state => ({
            ...state,
            nbNewClients,
        }));
    }, [dataProvider, today]);

    const fetchClientsCount = useCallback(async () => {
        let query = {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 1 },
            select: 'name',
        };
        const { total: nbClients } = await dataProvider.getList('users', query);

        setState(state => ({
            ...state,
            nbClients,
        }));
    }, [dataProvider]);

    const fetchOnlineDriversCount = useCallback(async () => {
        let query = {
            filter: { is_online: true },
            sort: { field: 'created_at', order: 'DESC' },
            pagination: { page: 1, perPage: 1 },
            select: 'name',
        };
        const { total: nbConnectedDrivers } = await dataProvider.getList(
            'livreurs',
            query
        );

        setState(state => ({
            ...state,
            nbConnectedDrivers,
        }));
    }, [dataProvider]);

    const fetchTotalCoursesCount = useCallback(async () => {
        let query = {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 1 },
            select: 'code',
        };
        const { total: nbCourses } = await dataProvider.getList(
            'courses',
            query
        );

        setState(state => ({
            ...state,
            nbCourses,
        }));
    }, [dataProvider]);

    const fetchNewCoursesCount = useCallback(async () => {
        let query = {
            filter: { created_at: { $gte: today.toDateString() } },
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 1 },
            select: 'code',
        };
        const { total: nbNewCourses } = await dataProvider.getList(
            'courses',
            query
        );

        setState(state => ({
            ...state,
            nbNewCourses,
        }));
    }, [dataProvider, today]);

    useEffect(() => {
        fetchNewClientsCount();
        fetchNewCoursesCount();
        fetchOnlineDriversCount();
        fetchClientsCount();
        fetchTotalCoursesCount();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/*<Welcome />*/}
            <Card>
                <CardContent>
                    <div style={styles.flex}>
                        <NbSubscriptions
                            title="pos.dashboard.subscriptions_today.title"
                            subtitle="pos.dashboard.subscriptions_today.subtitle"
                            value={state.nbNewClients}
                        />
                        <NbCourses
                            title="pos.dashboard.courses_today.title"
                            subtitle="pos.dashboard.courses_today.subtitle"
                            value={state.nbNewCourses}
                        />
                        <NbDrivers
                            title="pos.dashboard.drivers_online.title"
                            subtitle="pos.dashboard.drivers_online.subtitle"
                            value={state.nbConnectedDrivers}
                        />
                        <Spacer />
                    </div>
                </CardContent>
            </Card>
            <VerticalSpacer />
            <Card>
                <CardContent>
                    <div style={styles.flex}>
                        <NbSubscriptions
                            title="pos.dashboard.subscriptions_total.title"
                            subtitle="pos.dashboard.subscriptions_total.subtitle"
                            value={state.nbClients}
                        />
                        <NbCourses
                            title="pos.dashboard.courses_total.title"
                            subtitle="pos.dashboard.courses_total.subtitle"
                            value={state.nbCourses}
                        />
                        <Spacer />
                    </div>
                </CardContent>
            </Card>
        </>
    );
};

export default Dashboard;
