import * as React from 'react';
import { FC } from 'react';
import UserIcon from '@material-ui/icons/PersonPin';
import { useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: number;
    title?: string;
    subtitle?: string;
}

const NbDrivers: FC<Props> = ({ value, title, subtitle }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/livreurs"
            icon={UserIcon}
            title={translate(title)}
            subtitle={value ? `${value} ` : '--'}
            children={
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '10px',
                    }}
                >
                    <Typography color="textSecondary">
                        {translate(subtitle)}
                    </Typography>
                </div>
            }
        />
    );
};

export default NbDrivers;
